<template>
    <div class="newsAndMedia">
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8 text-center">
                        <h1 class="bold mb-md-3">{{$t('news_media.text1')}}<span class="secondary">{{$t('news_media.text2')}}</span> </h1>
                        <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English</p> -->
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space pt-0 mediaNews bg-white">
            <div class="container">
                <div class="row gy-3 g-lg-5">
                    <div class="col-12 col-md-4" v-for="list,key in first12" :key="key">
                        <div class="card-body">
                            <span class="mb-3 newsImg">
                                <v-lazy-image height="230" :src="`assets/images/news/${list.image}`" :alt="list.heading" />
                            </span>
                            <div class="content p-3 d-flex flex-column align-items-start justify-content-between">
                                <div class="wRte_bar">
                                    <span class="tag status medium">{{list.tag}}</span>
                                    <h6 class="f-14 semibold">{{list.heading}}</h6>
                                    <p class="secondary f-13">{{list.date}}</p>
                                    <p v-html="list.description"></p>
                                </div>
                                <a class="button secondary zulu_btn rounded border-button px-ms-4" rel="nofollow" :href="list.link" target="_blank">{{$t('news_media.text3')}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center" v-if="newsList.length > 12">
                        <a class="button fillBtn zulu_btn mt-4 px-md-4" href="javascript:void(0);" @click="showMore = !showMore">{{!showMore ? 'View More' : 'View less'}}</a>
                    </div>
                </div>
            </div>
        </section>
        </div>
</template>
<script>
import news from "../../../public/assets/json/news-media";
export default {
    data() {
        return {
            newsList: news,
            showMore : false
        };
    },
    computed: {
        first12(){
            if(this.showMore){
                return this.newsList
            }else{
                return this.newsList.slice(0,12);
            }
        },
    },
}
</script>